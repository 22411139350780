<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Заголовок(RU)" prop="title_ru">
						<el-input v-model="formData.title_ru" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Заголовок (UA)" prop="title_uk">
						<el-input v-model="formData.title_uk" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Стоимость доставки" prop="price">
						<el-input v-model="formData.price" />
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin } from '@/mixins';

export default {
	mixins: [itemFormMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				title_ru: '',
				title_uk: '',
				price: ''
			}
		};
	},

	computed: {
		rules: () => ({
			title_ru: required,
			title_uk: required,
			price: required
		})
	},

	methods: {}
};
</script>
